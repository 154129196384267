import _ from 'lodash';

export function getParamId (props) {
  return props.match.params.id;
}

export function getParam (props, param) {
  return props.match.params[param];
}

// TLC(ROOT): BidExport => getBuildData => TabulationGenerator => addNewSummarySheet => generateSummaryColumns => addIncumbentPricingColumn => generateOldLotPriceTotal => lineItemTotal (LEAF)
// TLC(ROOT): BidExport => generateExcel => TabulationGenerator => addNewSummarySheet => generateSummaryColumns => addIncumbentPricingColumn => generateOldLotPriceTotal => lineItemTotal (LEAF)

/**
 * Baseline line item total.
 * @param price {number} Baseline price per unit
 * @param extendedQuantity {number} Extended quantity, numerator of the quantity of units calculation
 * @param quantity {number} Items per unit, divisor of the quantity of units calculation
 * @returns {number} Price times the quantity of units calculation
 * deprecated TLC(LEAF): Change to a new function to consider the Total Landed Cost. (duplicity)
 */
export function lineItemTotal (price, extendedQuantity, quantity) {
  // TLC: REPLACE with components/shared/utils/prices/totalPrice.ts
  return (price * (extendedQuantity / quantity));
}

/**
 * Response line item total calculation.  This represents the total price including all line item factors.
 * @param price {number} Response price per unit
 * @param discount {number} Response discount per unit
 * @param surcharge {number} Logistics charge to applied to line item price
 * @param extendedQuantity {number} Extended quantity, numerator of the quantity of units calculation
 * @param quantity {number} Items per unit, divisor of the quantity of units calculation
 * @returns {number} Per unit calculations times quantity of units calculation, then add the logistics charge
 * deprecated TLC(LEAF): Change to a new function to consider the Total Landed Cost.
 */
// TLC(ROOT): (non-autosave) VendorBid => BidSubmission => BidSubmissionActionButtons => EventEnded => calcAwardedLineItemPrice => responseLineItemTotal
// TLC(ROOT): VendorBidAutoSave => BidSubmissionAutosave => BidSubmissionActionButtons => EventEnded => calcAwardedLineItemPrice => responseLineItemTotal
// TLC(ROOT): (non-autosave) VendorBid => BidSubmission => Lot => LineItem => EditableFields => calcAwardedLineItemPrice => responseLineItemTotal
// TLC(ROOT): VendorBidAutoSave => BidSubmissionAutosave => Lot (LotAutosave) => LineItem (LineItemAutosave) => EditableFields => calcAwardedLineItemPrice => responseLineItemTotal
export function responseLineItemTotal (price, discount, surcharge, extendedQuantity, quantity) {
  // convert all strings to numbers
  // TLC: REPLACE with components/shared/utils/prices/effectivePrice.ts
  const parsedPrice = parseFloat(price);
  const parsedDiscount = parseFloat(discount);
  const parsedSurcharge = parseFloat(surcharge);
  const parsedExtendedQuantity = parseFloat(extendedQuantity);
  const parsedQuantity = parseFloat(quantity);

  return ((parsedPrice - parsedDiscount) * (parsedExtendedQuantity / parsedQuantity)) + parsedSurcharge;
}

export function userName (firstName, lastName, email) {
  const name = `${(firstName || '')} ${(lastName || '')}`;

  return _.isEmpty(_.trim(name)) ? email : name;
}

export function userNameAndEmail (data) {
  return {
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  };
}

/**
 * Converts the values to floats, then applies strict equality.  Defaults each value to 0.00.
 * @param firstValue {number, string} First value to compare with
 * @param secondValue {number, string} Second value to compare with
 * @returns {boolean} True if the float representation of the two values are equal, false otherwise.
 */
export function floatEqual (firstValue, secondValue) {
  const firstFloat = parseFloat(firstValue) || 0.00;
  const secondFloat = parseFloat(secondValue) || 0.00;

  return firstFloat === secondFloat;
}

export function parseUndefinedInput (value) {
  return _.isNil(value) ? '' : value;
}

export function displayNumberWithCommas (amount) {
  const formatter = Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 });

  return formatter.format(amount);
}

export function parseSortOrder (value) {
  switch (value) {
    case 'desc': return 'desc';
    case 'descend': return 'desc';
    case 'asc': return 'asc';
    case 'ascend': return 'asc';
    default:
      return '';
  }
}
