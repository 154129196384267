import ExcelJS from 'exceljs';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { BID_REQUEST_ID, CAT_QUOTE, DOWNLOAD_QUOTE_SPREADSHEET } from './analytics_constants';

export const EDITABLE_COLOR = '#000'; // $theme-color

export const MIN_COLUMN_WIDTH = 20;
export const MAX_COLUMN_WIDTH = 40;

export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/bmp',
  'image/svg',
  'image/ico'
];

export const TABLE_MIME_TYPES = [
  'text/csv; charset=utf-8',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.apple.numbers'
];

export const getFileCellOptionsTypes = t => ({
  characterLimitTitle: t('general.character_limit'),
  characterLimitPrefix: t('general.character_limit_prefix'),
  decimalTitle: t('general.decimal'),
  decimalPrompt: t('general.value_must_be_number'),
  cellOptionErrorTitle: t('general.invalid_input'),
  cellOptionErrorPrefix: t('general.enter')
});

/**
 * Builds an object to be set as the validations for an excel cell based on the options presented.
 * @param {[{type, optionText}]} options Presented options for this cell.
 * return {{}} Object containing the validations to be set to the cell.
 */
const getAnswerOptionsCellValidation = (t, global, options = []) => {
  const firstOption = options[0];
  const SINGLE_TEXT_TYPE = global.line_items.single_text_question_type;
  const SINGLE_NUMERIC_TYPE = global.line_items.single_numeric_question_type;
  const DEFAULT_CHARACTER_LIMIT = global.lots.custom_column_headers.default_character_limit;

  let dataValidation = {};

  if (!firstOption) return dataValidation;

  if (firstOption.type === SINGLE_TEXT_TYPE) { // Single Text Validation
    const characterLimit = Number.parseInt(firstOption.optionText, 10) || DEFAULT_CHARACTER_LIMIT;

    dataValidation = {
      type: 'textLength',
      operator: 'lessThan',
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: t('general.character_limit'),
      error: t('general.character_limit_prompt', { characterLimit }),
      allowBlank: true,
      formulae: [characterLimit]
    };
  }
  else if (firstOption.type === SINGLE_NUMERIC_TYPE) { // Single Numeric Validation
    dataValidation = {
      type: 'decimal',
      operator: 'greaterThan',
      allowBlank: true,
      showInputMessage: true,
      formulae: [-1000000],
      errorStyel: 'error',
      errorTitle: t('general.decimal'),
      error: t('general.value_must_be_number')
    };
  }
  else { // Yes/No, Multi currently
    const optionsFormulaString = options.map(option => option.optionText).join(',');
    const optionsDisplayString = options.map(option => option.optionText).join(' or ');

    dataValidation = {
      type: 'list',
      allowBlank: true,
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: t('general.invalid_input'),
      error: t('general.value_must_be_option', { optionsString: optionsDisplayString }),
      formulae: [`"${optionsFormulaString}"`]
    };
  }

  return dataValidation;
};

const createExcelWorkbook = (props = {}) => {
  const workbook = new ExcelJS.Workbook();

  workbook.creator = props.creator || 'Arkestro';
  workbook.created = props.created || new Date();
  workbook.modified = props.modified || new Date();

  return workbook;
};

/**
 *
 * @param workbook
 * @param {[{ header:string, key:string, width:Number }]} columns
 * @param name
 * @param headers
 * @param footers
 * @param pageSetup
 * @returns {Worksheet}
 */
const createWorksheet = (t, workbook, columns = [], name = '', headers = {}, footers = {}, pageSetup = {}) => {
  const sheetName = name || t('general.sheet_one');
  const worksheet = workbook.addWorksheet(sheetName, {
    pageSetup,
    headerFooter: { firstHeader: headers.firstHeader || '', firstFooter: footers.firstFooter || '' }
  });

  if (columns.length > 0) worksheet.columns = columns;
  worksheet.state = 'visible';

  return worksheet;
};

const addRowsToWorksheet = (t, global, worksheet, rows) => {
  rows.forEach(row => {

    worksheet.addRow(row);
    const { validationColumns } = row;

    if (validationColumns) {
      const editableRow = worksheet.lastRow;

      Object.keys(validationColumns).forEach(key => {
        const options = validationColumns[key] || [];

        if (options.length > 0) {
          editableRow.getCell(key).dataValidation = getAnswerOptionsCellValidation(t, global, options);
        }
      });

      editableRow.commit();
    }
  });

  return worksheet;
};

export const generateExcelWorkbook = (t, global, workbookOptions) => {
  const { columnHeaders, rows } = workbookOptions;

  const workbook = createExcelWorkbook();
  const worksheet = createWorksheet(t, workbook, columnHeaders);

  addRowsToWorksheet(t, global, worksheet, rows);
  return workbook;
};

export const generateExcelBufferAsBase64String = async workbook => {
  const buffer = await workbook.xlsx.writeBuffer();

  return buffer.toString('base64'); // This is a node thing but appears to work?
};

export const generateDownloadFromUrl = (url, fileName = '') => {
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 1000);
};

export const parseDownloadUrlResponse = (t, response) => {
  if (response.success) {
    if (response.status === 'completed' && response.url) {
      generateDownloadFromUrl(response.url);
      return ['completed', {}];
    }
    if (response.status === 'queuing'){
      return ['queuing', {
        message: t('general.file_download_queued'),
        description: t('general.file_download_wait'),
        duration: 10,
        message_type: 'success'
      }];
    }
  }
  return ['error', {
    message: t('general.file_download_error'),
    description: t('general.file_queue_failed'),
    duration: 10
  }];
};

export const generateFileDownloadFromBuffer = (buffer, fileName, type, extension, base64 = false) => {
  if (typeof window === 'undefined') return;

  const decodedBuffer = base64 ? [Buffer.from(buffer, 'base64')] : [buffer];
  const blob = new Blob(decodedBuffer, { type });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}${extension}`;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 1000);
};

export const generateExcelDownload = (workbook, filename, protectAllSheets = false) => {
  if (protectAllSheets) {
    workbook.worksheets.forEach(worksheet => worksheet.protect('', {
      selectUnlockedCells: true,
      selectLockedCells: true,
      formatColumns: true,
      sort: true,
      autoFilter: true,
      deleteRows: true
    }));
  }
  workbook.xlsx.writeBuffer()
    .then(buffer => {
      generateFileDownloadFromBuffer(buffer, filename, 'application/xlsx', '.xlsx');
    });
};

export const generateCSVDownload = (workbook, filename) => {
  workbook.csv.writeBuffer()
    .then(buffer => {
      generateFileDownloadFromBuffer(buffer, filename, 'application/csv', '.csv');
    });
};

/**
 * Reads the provided excel file into an array buffer, then adds that a workbook.
 * @param {[]} file File MDN object, will be converted to an array buffer then loaded by ExcelJS.
 * @returns {Promise} ExcelJS workbook object with input from provided file.
 */
export const readExcelFile = async file => {
  const workbook = new ExcelJS.Workbook();
  const buffer = await file.arrayBuffer();

  await workbook.xlsx.load(buffer);

  return workbook;
};

/**
 * Extracts the result from the cell value if the cell contains a formula.
 * @param {string, number, {formula: string, result: string|number}} cellValue Value of the cell
 * @returns {string, number} If it's an object, then the value of the result property, otherwise returns the cell value.
 */
export const extractResultFromCell = cellValue => {
  if (typeof cellValue === 'object') {
    return cellValue.result;
  }
  return cellValue;
};

/**
 * Extracts the rows and headers from the worksheet to be consumed as a JSON object. Assumes that the first row in the
 * sheet corresponds to the headers, a flat file format.
 * @param {{}} worksheet ExcelJS worksheet from which to extract the rows and headers.
 * @returns {{name: '', headerIndexMap: {}, rows: []}} Object containing rows and headers.
 */
export const extractRowsAndHeaderMap = worksheet => {
  const { name } = worksheet;
  const rawRows = [];

  worksheet.eachRow(row => rawRows.push(row));
  // exceljs always adds a null to be the zero index of a row, and the A1 will always be the index of 1, etc.
  // https://github.com/exceljs/exceljs/issues/698
  let rows = rawRows.map(row => row.values.slice(1));

  let headerIndexMap = {};

  if (rows.length > 0) {
    const headerKVPairs = rows[0]
      .map((header, index) => ([header?.toLowerCase().trim(), index]))
      .filter(headerKVPair => headerKVPair && headerKVPair.length > 0 && !!headerKVPair[0]);

    headerIndexMap = Object.fromEntries(headerKVPairs);
    rows = rows.slice(1);
  }

  return { name, headerIndexMap, rows };
};

/**
 * Gets the column for a given header by finding the keys.
 * @param {String} header string form of the header
 * @param {Worksheet} worksheet ExcelJS worksheet on which to find the column for the header
 * @return {Column} Column for worksheet or undefined if not found
 */
const getColumnForHeader = (header, worksheet) => (worksheet.columns.find(col => col.key === header.key));

/**
 * Sets the width for a provided header cell.  Constrains the width to be relative to the string length and
 * between the +MIN_COLUMN_WIDTH+ and +MAX_COLUMN_WIDTH+ values.
 * @param {{ header: String }} headerObject Simple header object for ExcelJS.
 * @return {(*&{width: number, header: string})|undefined} Modified copy of the headerObject with
 *   the width updated or undefined if invalid headerObject.
 */
export const getWidthFittedHeader = headerObject => {
  if (!headerObject || !headerObject.header) return undefined;
  const { header = '' } = headerObject;
  const width = Math.min(Math.max(MIN_COLUMN_WIDTH, header.length), MAX_COLUMN_WIDTH);

  return { ...headerObject, header, width };
};

/**
 * Gets the fitted headers in the { header: x, key: y } format that ExcelJS expects.
 * @param headers
 */
export const getFittedSpreadsheetHeaders = headers => headers.map(incomingHeader =>
  getWidthFittedHeader({ header: incomingHeader, key: incomingHeader }));

export const fillBackgroundColor = (header, worksheet, color) => {
  const column = getColumnForHeader(header, worksheet);

  if (!column) return;
  column.eachCell(cell => {
    cell.fill = { // This mutation is the preferred way, unfortunately
      type: 'pattern',
      pattern: 'lightUp',
      fgColor: {
        argb: color.replace('#','') // Doesn't like the octothorpe for some reason
      }
    };
  });
};

export const addProtectionToCells = (header, worksheet, protection) => {
  const column = getColumnForHeader(header, worksheet);

  if (!column) return;
  column.eachCell(cell => {
    cell.protection = protection;  // This mutation is the preferred way, unfortunately
  });
};

export const addAlignmentToRow = (rowNumber, worksheet, alignment) => {
  const row = worksheet.getRow(rowNumber);

  if (!row) return;
  row.eachCell(cell => {
    cell.alignment = alignment;
  });
};

export const hideColumn = (header, worksheet) => {
  const column = getColumnForHeader(header, worksheet);

  if (!column) return;
  column.hidden = true;
};

export const addAutoFilterToSheet = worksheet => {
  const lastColumnLetter = worksheet.lastColumn.letter;

  worksheet.autoFilter = { from: 'A1', to: `${lastColumnLetter}1` };
};

export const extractSheetsRowsAndHeaders = workbook => (
  workbook.worksheets.map(worksheet => extractRowsAndHeaderMap(worksheet))
);

/**
 * Runs a gql query for getting a spreadsheet with the provided query and table options.
 * @param {Object} gqlClient Client for graphql, in this the Apollo client for our Node server is expected.
 * @param {string} query Graphql query as specified in gql_queries.
 * @param {{}} tableOptions Map of options for table to be used when constructing.
 */
export const queryForSpreadsheet = async (t, gqlClient, query, tableOptions) => {
  attachTrackingAnalytics(DOWNLOAD_QUOTE_SPREADSHEET, {
    [BID_REQUEST_ID]: tableOptions.requestId,
    category: CAT_QUOTE
  });

  const result = await gqlClient.query({
    query,
    variables: {
      tableOptions
    }
  });

  if (!result || !result.data) return;
  const sheet = result.data.unifiedBidTable;
  const { requestName } = tableOptions;
  const filename = t('bid_request.download_filename', { name: requestName });

  generateFileDownloadFromBuffer(sheet, filename, 'application/xlsx', '.xlsx', true);
};
