// Color name here should line up with variable names from _variables.scss
import { gray13, green7, blue6, orange6, orange8, colorErrorActive, colorSuccessText } from
  '@arkestro/arkestro-design-system';

const colors = {
  themeIconColor: {
    base: '#000',
    inverted: '#fff'
  },
  grey: {
    lightest: '#f6f6f6',
    lighter: '#e1e3e6',
    mediumDark: '#6c6f78',
    dark: '#242528'
  },
  green: {
    base: gray13,
    medium: '#358a61',
    background: '#edf5f4'
  },
  red: {
    base: '#be0000',
    dark: '#9e0000'
  },
  yellow: {
    dark: '#a67300',
    background: '#fffbee'
  },
  statuses: {
    null: '#aaaaaa',
    undefined: '#aaaaaa',
    not_invited: '#aaaaaa',
    'not invited': '#aaaaaa',
    'Not Invited': '#aaaaaa',
    draft: '#a67300',
    Draft: '#a67300',
    pending: '#a67300',
    Pending: '#a67300',
    accepted: '#045300',
    Accepted: '#045300',
    approved: '#045300',
    Approved: '#045300',
    bounced: '#d47000',
    Bounced: '#d47000',
    rejected: '#cd4141',
    Rejected: '#cd4141',
    declined: '#cd4141',
    Declined: '#cd4141',
    preferred: '#1D93D3',
    Preferred: '#1D93D3',
    exists: '#4060FF',
    Exists: '#4060FF'
  },
  requestStates: {
    response_submitted: green7,
    responseSubmitted: green7,
    draft: gray13,
    open: green7,
    open_for_questions: green7,
    openForQuestions: green7,
    open_for_bidding: green7,
    openForBidding: green7,
    closed: gray13,
    awarded: gray13,
    ready_to_award: blue6,
    readyToAward: blue6,
    award_pending: blue6,
    awardPending: blue6,
    unawarded: orange6,
    notAwarded: orange6,
  },
  requestTypes: {
    proposal: '#4060ff',
    proposals: '#4060ff',
    information: '#be00a0',
    quote: '#1d93d3',
    quotes: '#1d93d3',
    purchase_request: '#246847',
    purchaseRequest: '#246847'
  },
  requestStatusOverviewColors: {
    draft: gray13,
    response_submitted: orange8,
    open_for_questions: green7,
    open_for_bidding: green7,
    closed: gray13,
    awarded: gray13,
    unawarded: orange6,
    ready_to_award: blue6,
    award_pending: blue6
  }
};

const DEFAULT_COLOR_LIMITS = [0.5, 0.8];

// Take a value and returns its corresponding color. Assumes 0 is bad e.g. "red"
// @param value [Float|Number] Value to lookup
// @param shouldReturnCode [Boolean] When true, returns the hex code
// @param limits [Array] Color breakpoints. Assumes cap of 1.0
// @return [String] Color as string name by default or hex code with flag enabled
export const colorFromValue = (value, shouldReturnCode = false, limits = DEFAULT_COLOR_LIMITS) => {
  let color = { name: 'bad', hex: colorErrorActive };

  if (value > limits[0]) color = { name: 'notice', hex: orange8 };
  if (value > limits[1]) color = { name: 'good', hex: colorSuccessText };

  return shouldReturnCode ? color.hex : color.name;
};

export default colors;
