import { utc } from 'moment';

const ENVIRONMENT = process.env.NEXT_PUBLIC_ARKESTRO_ENV;
// To test this service locally, add 'development' to the array below and do not commit.
const VALID_ENVIRONMENTS = ['dev', 'stage', 'sandbox', 'prod'];
const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;
const USE_INTERCOM_INTEGRATION = false;

class SegmentService {
  static initialize = () => {
    if (typeof window === 'undefined') return;
    if (!VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;

    if (SEGMENT_KEY) {
      !function (){const analytics=window.analytics=window.analytics||[];

        if (!analytics.initialize) if (analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else {analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware'];analytics.factory=function (e){return function (){const t=Array.prototype.slice.call(arguments);

          t.unshift(e);analytics.push(t);return analytics;};};for (let e=0;e<analytics.methods.length;e++){const key=analytics.methods[e];

          analytics[key]=analytics.factory(key);}analytics.load=function (key,e){const t=document.createElement('script');

          t.type='text/javascript';t.async=!0;t.src=`https://cdn.segment.com/analytics.js/v1/${  key  }/analytics.min.js`;const n=document.getElementsByTagName('script')[0];

          n.parentNode.insertBefore(t,n);analytics._loadOptions=e;};analytics._writeKey=SEGMENT_KEY;analytics.SNIPPET_VERSION='5.2.0';

        // Disabling Intercom integration from loading entirely to avoid competing calls to Intercom from
        // here and our manual Intercom initialization.
        analytics.load(SEGMENT_KEY, { integrations: { Intercom: USE_INTERCOM_INTEGRATION } });
        analytics.page();
        }}();
    }
  }

  // Segment + Intercom Docs
  // https://segment.com/docs/connections/destinations/catalog/intercom/
  static configureUser = (options = {}) => {
    if (!VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;

    const { currentUser } = options;

    if (typeof window === 'undefined') return;
    if (!window.analytics?.identify) return;
    if (!currentUser?.id) return;

    // When a user is not being impersonated, the true user is the same as the current user.
    const isImpersonating = currentUser.impersonating;
    const trackedUser = isImpersonating ? currentUser.true_user : currentUser;

    if (!trackedUser?.id) return;

    // As of this comment, the only integrations through Segment are:
    // Satismeter
    // Downstream, Amplitude does pull this data, but we will block email and organization name
    const {
      role,
      organization_name: organizationName = 'Arkestro System',
      organization_id: organizationId = 0,
      email,
      planType,
      company = {},
      category,
      id,
      is_self_serve: isSelfServe = false,
      is_demo_account: isDemoAccount = false
    } = trackedUser;

    // TODO: This feels uncomfortable, we should do better later
    let companyId = 'superadmin';
    if (category === 'buyer' && company && company.id) {
      companyId = company.id;
    }
    else if (category === 'vendor') {
      companyId = 'vendor';
    }

    const isSuperadminSession = isImpersonating;
    const superadminUser = isImpersonating ? currentUser.true_user?.email : '';
    const timestamp = utc();
    const timestampUnix = timestamp.unix();

    // See comment below and IntercomService
    // const intercomParams = {
    //   user_hash : trackedUser.intercom_hmac,
    //   api_base: trackedUser.intercom_api_base,
    //   app_id: EU_INTERCOM_APP_ID,
    // };

    // if (trackedUser.emea) intercomParams.app_id = EU_INTERCOM_APP_ID;

    window.analytics.identify(id, {
      userType: category,
      companyId,
      role,
      organizationName,
      organizationId,
      email,
      isSelfServe,
      isDemoAccount,
      planType,
      isSuperadminSession,
      superadminUser,
      timestamp
    }, {
      integrations: {
        Intercom : USE_INTERCOM_INTEGRATION
      }
    });
    // Set the session id for Amplitude integration, tracking, and pages.
    window.amplitudeSessionID = timestampUnix;

    // Pass integrations as third argument to analytics.identify() if we need to load
    // Intercom via Segment. Loading through Segment has problems working with both US
    // and EU Intercom apps so we load Intercom separately in the IntercomService.
    // {
    //   integrations: {
    //     Intercom : USE_INTERCOM_INTEGRATION
    //   }
    // }

    if (organizationId) {
      window.analytics.group(organizationId);
    }
  };
}

/**
 * Gets the structure for the Amplitude integration and properties with the Session ID.
 * @param sessionId Id from the window, set at the time of identify for Segment.
 * @returns {{integrations: {Amplitude: {session_id}}}} Expected object structure for segment
 */
const getAmplitudeProps = sessionId => (
  {
    integrations: {
      Amplitude: {
        session_id: sessionId.toString()
      }
    }
  }
);

export const attachPageAnalytics = (pageName, pageProps ) => {
  try {
    if (!VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;
    if (typeof window === 'undefined' || !window.analytics?.page) return;
    const amplitudeProps = window?.amplitudeSessionID ? getAmplitudeProps(window.amplitudeSessionID) : {};

    window.analytics.page( pageName, pageProps, amplitudeProps );
  } catch (error) {
    console.error('Error attaching page analytics', error);
  }
};

export const attachTrackingAnalytics = (actionName, actionProps) => {
  try {
    if (!VALID_ENVIRONMENTS.includes(ENVIRONMENT)) return;
    if (typeof window === 'undefined' || !window.analytics?.track) return;
    const amplitudeProps = window?.amplitudeSessionID ? getAmplitudeProps(window.amplitudeSessionID) : {};

    window.analytics.track( actionName, actionProps, amplitudeProps );
  } catch (error) {
    console.error('Error attaching tracking analytics', error);
  }
};

export default SegmentService;
